import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const relativite = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Relativité" />
    <h3 className='underline-title'>Relativité</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Toi glacier<br />
        Inutilisable au Pôle boréal<br />
        Descends sous l'Équateur en Feu <br />
        Pour rafraîchir la Primus embrasée<br />
      </p>
      <p>
        Toi banane<br />
        Quitte l'Afrique indifférente<br />
        Où la mangue cardioforme avec toi rivalise<br />
        Va en Occident où t'attend toujours<br />
        L'accueil impatient des enfants friands<br />
      </p>
      <p>
        Toi automobile<br />
        Ne t'embouteille pas aux USA<br />
        L'Afrique piétonne s'impatiente<br />
        Pour te louer l'espace vital<br />
        Où tu peux rouler sans entraves<br />
      </p>
      <p>
        Toi industriel allemand<br />
        Ne pollue pas l'air germain d'usines<br />
        Viens en Afrique immense<br />
        Où d'étendues sans mesure<br />
        Sont affermées encore<br />
        Aux primates grimaciers<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default relativite
